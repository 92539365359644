import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { StaticNotification } from '@monorepo/components/DataDisplay';
import { Form, StandardButton } from '@monorepo/components/DataEntry';
import { IconItem, IconList, NumberedList, Text } from '@monorepo/components/TextComponents';
import { SummarySchema } from '@monorepo/shared/lib/schemas';
import { scrollToPosition } from '@monorepo/shared/lib/utils';
import { FlowState, InsuranceDocs, MediaTypes, Organization } from '@monorepo/types';

import { useNavigation } from '@common/hooks';
import { selectPlatformState } from '@common/store';
import { paths } from '@common/utils';
import { useFlowState } from '@funnel/hooks';
import { selectCartState, useGetCartOrganizationQuery } from '@funnel/store';

type SummaryFormProps = {
	state: FlowState;
	documents: InsuranceDocs;
	showEffectiveDate?: boolean;
	isInReview?: boolean; // indicates whether we're in approval after review or not
	isLoading?: boolean;
	onSubmit: (fields: SummarySchema) => void;
};

export const SummaryForm: FC<SummaryFormProps> = ({
	state,
	documents,
	showEffectiveDate,
	isInReview,
	isLoading,
	onSubmit,
}) => {
	const {
		query: { cartGuid },
	} = useRouter();
	const { t } = useTranslation(['common']);
	const flowState = useFlowState({ cartGuid: cartGuid as string, isInReview });
	const { isPortal } = useSelector(selectPlatformState);
	const cart = useSelector(selectCartState);
	const { isB2BFunnel } = useNavigation();

	const { data: organization = {} as Organization } = useGetCartOrganizationQuery(
		isB2BFunnel && cartGuid ? (cartGuid as string) : skipToken
	);

	const termsConditionsDocument = documents?.documents?.find((document) => document.type === MediaTypes.GeneralTerms);
	const serviceGuidDocument = documents?.documents?.find((document) => document.type === MediaTypes.ServiceGuide);
	const formContext = useFormContext<SummarySchema>();

	useEffect(() => {
		if (organization?.iban && typeof organization.iban === 'string') {
			formContext.setValue('iban', organization.iban, { shouldValidate: true });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organization?.iban]);
	return (
		<Form.Context<SummarySchema>>
			{({ handleSubmit, formState: { errors, isValid }, register, control, watch }) => {
				return (
					<>
						{(flowState === 'wel-itc-happy' || flowState === 'geen-itc-sad' || flowState === 'wel-itc-sad') && (
							<StaticNotification status='info'>
								<Text weight='semibold' variant='body-m'>
									{t('page.funnel.finalize.summary.inReview.requestExpectations.title')}
								</Text>

								<NumberedList
									className='mt-16'
									items={[
										...(flowState === 'wel-itc-sad'
											? [
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.1'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.2'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.3'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.4'),
												]
											: []),
										...(flowState === 'wel-itc-happy'
											? [
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.5'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.6'),
													t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.7'),
												]
											: []),
										...(flowState === 'geen-itc-sad'
											? [t('page.funnel.finalize.summary.inReview.requestExpectations.transferService.8')]
											: []),
									]}
								/>
							</StaticNotification>
						)}

						<div className='rounded-18 bg-grayscale0 shadow-1 p-24'>
							<Form onSubmit={handleSubmit(onSubmit)} className='space-y-32'>
								<div className='space-y-40'>
									{(state === 'wel-itc-happy' ||
										state === 'geen-itc-happy' ||
										state === 'inreview-wel-itc-sad' ||
										state === 'inreview-geen-itc-sad') && (
										<div className='space-y-24'>
											<Form.Group isRendered={state === 'geen-itc-happy' || state === 'inreview-geen-itc-sad'}>
												<Form.Label>{t('forms.summaryForm.fields.effectiveDateLabel')}</Form.Label>
												<Form.DateInput
													fieldSize='lg'
													min={cart?.minimum_effective_date}
													name='effective_date'
													control={control}
													errors={errors.effective_date}
													placeholder={t('forms.summaryForm.fields.effectiveDatePlaceholder')}
													endIcon='calendar-alt'
													register={register}
													className='w-full'
													data-testid='summary-form-date-input'
												/>
											</Form.Group>

											<Form.Group
												isRendered={
													state === 'wel-itc-happy' ||
													state === 'geen-itc-happy' ||
													state === 'inreview-wel-itc-sad' ||
													state === 'inreview-geen-itc-sad'
												}>
												<Form.Label>{t('forms.summaryForm.fields.ibanTitle')}</Form.Label>
												<Form.IbanInput
													fieldSize='lg'
													name='iban'
													disabled={!!(isPortal || organization?.iban)}
													startIcon='credit-card'
													placeholder='NL60ABNA9238197806'
													errors={errors.iban}
													register={register}
													className='w-full'
													data-testid='summary-form-iban-input'
												/>
											</Form.Group>
										</div>
									)}
									<div className='space-y-32'>
										<div className='space-y-24'>
											<Text variant='body-m' weight='semibold'>
												{t(
													isInReview ? 'forms.summaryForm.checklist.title' : 'forms.summaryForm.checklist.titleInReview'
												)}
											</Text>
											<IconList>
												{isB2BFunnel && (
													<IconItem
														item={{
															icon: 'truck-loading',
															titleWeight: 'regular',
															title: t('forms.summaryForm.checklist.items.6'),
														}}
													/>
												)}

												{(state === 'wel-itc-happy' || (state === 'inreview-wel-itc-sad' && !showEffectiveDate)) && (
													<IconItem
														item={{
															icon: 'times-circle',
															titleWeight: 'regular',
															title: t('forms.summaryForm.checklist.items.1'),
														}}
													/>
												)}

												{(state === 'wel-itc-happy' ||
													state === 'geen-itc-happy' ||
													state === 'inreview-wel-itc-sad' ||
													state === 'inreview-geen-itc-sad') && (
													<IconItem
														item={{
															icon: 'sync',
															titleWeight: 'regular',
															title: t('forms.summaryForm.checklist.items.2'),
														}}
													/>
												)}

												{(state === 'wel-itc-happy' ||
													state === 'geen-itc-happy' ||
													state === 'inreview-wel-itc-sad' ||
													state === 'inreview-geen-itc-sad') &&
													!isPortal && (
														<IconItem
															item={{
																icon: 'university',
																titleWeight: 'regular',
																title: t('forms.summaryForm.checklist.items.3'),
															}}
														/>
													)}

												{(state === 'wel-itc-happy' ||
													state === 'geen-itc-happy' ||
													state === 'inreview-wel-itc-sad' ||
													state === 'inreview-geen-itc-sad') && (
													<IconItem
														item={{
															icon: 'file-alt',
															titleWeight: 'regular',
															title: (
																<Trans
																	i18nKey={'forms.summaryForm.checklist.items.4'}
																	components={{
																		scroll: (
																			<span
																				className='text-primary cursor-pointer underline'
																				onClick={() => scrollToPosition('insurance-documents')}
																			/>
																		),
																	}}
																/>
															),
														}}
													/>
												)}

												<IconItem
													item={{
														icon: 'file-bookmark-alt',
														titleWeight: 'regular',
														title: (
															<Trans
																i18nKey={'forms.summaryForm.checklist.items.5'}
																components={{
																	terms: (
																		<Link
																			target='_blank'
																			title={`${termsConditionsDocument?.name}`}
																			href={`/${termsConditionsDocument?.path}`}
																			className='text-primary underline'
																		/>
																	),
																	serviceGuide: (
																		<Link
																			target='_blank'
																			title={`${serviceGuidDocument?.name}`}
																			href={`/${serviceGuidDocument?.path}`}
																			className='text-primary underline'
																		/>
																	),
																	privacy: (
																		<Link
																			target='_blank'
																			rel='noreferrer'
																			href={paths.content.privacy_statement}
																			className='text-primary underline'
																		/>
																	),
																}}
															/>
														),
													}}
												/>
											</IconList>
										</div>

										<Form.AcceptanceStatementCheckbox
											name='terms_agreement'
											label={t('forms.summaryForm.fields.accept')}
											register={register}
											checked={watch('terms_agreement')}
											data-testid='summary-form-agreement-input'
										/>
									</div>
								</div>

								{/* Submit */}
								<StandardButton
									type='submit'
									variant='cta'
									label={t(
										isInReview || flowState === 'wel-itc-happy' || flowState === 'geen-itc-happy'
											? 'common.ctaCloseOff'
											: 'common.ctaRequest'
									)}
									isLoading={isLoading}
									disabled={!isValid || isLoading}
									className='w-full'
									iconRight='angle-right'
									data-testid='summary-form-submit-button'
								/>
							</Form>
						</div>
					</>
				);
			}}
		</Form.Context>
	);
};
